import { Component, inject, Injector, OnDestroy, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { DatasetModel } from '../../dataset/dataset.model';
import { FormModel } from '../../form/form.model';
import { updateFormList } from '../../form/store/form.actions';
import { loadDatasetList, resetCurrentDataset } from '../../dataset/store/dataset.actions';
import { AppState, getState } from '../../store/models/app.state';
import { globalLoading, updateContextTitle } from '../../store/actions/ui.actions';
import { ProjectModel, ProjectTypeEnum } from '../project.model';
import {
  checkCurrentProjectEditLocked,
  createProject,
  dispatchedCreateProject,
  pendingCreateProject,
  refreshCurrentProject,
  selectCurrentProjectManageProject,
  successCreateProject,
  updateProject,
  updateProjectField,
  updateProjectStatus,
} from '../store/project.actions';
import { CurrentProjectManage } from '../store/project.state';
import { getUsersByOrganization } from '../../user/store/user.actions';
import { PendingChangesControl } from '../../shared/guards/pending-changes.guard';
import { INPUT_DEBOUNCE_TIME, VERSION_PATTERN } from '../../core/config/app.constants';
import { BaseComponent } from '../../shared/base.class';
import { ProjectService } from '../project.service';
import { isEqual, isObject } from 'lodash-es';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Statuses } from '../../shared/models/statuses.enum';
import { toSignal } from '@angular/core/rxjs-interop';
import { UtilsService } from '../../core/utils.service';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { PermissionsEnum } from '../../permission/permissions.enum';

@Component({
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent extends BaseComponent implements OnInit, OnDestroy, PendingChangesControl {
  form: UntypedFormGroup;
  datasets: Observable<DatasetModel[]>;
  currentProject$: Observable<ProjectModel>;
  originalProject$: Observable<ProjectModel>;
  amendProject$: Observable<ProjectModel>;
  projectState$: Observable<CurrentProjectManage>;
  forms: Observable<FormModel[]>;
  sponsorFormCtrl: FormControl = new FormControl();
  // dossierUsers: BehaviorSubject<UserModel[]> = new BehaviorSubject([]);
  // agentUsers: BehaviorSubject<UserModel[]> = new BehaviorSubject([]);
  projectId: number;
  injector: Injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  confirmationService: PharConfirmDialogService = inject(PharConfirmDialogService);
  projectSignal: Signal<ProjectModel> = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });
  // rebuildDatasetFieldSelector: BehaviorSubject<boolean> = new BehaviorSubject(true);
  refresh = new BehaviorSubject<boolean>(true);
  hasPendingChanges$: Observable<boolean>;
  isNewProject = false;
  // filteredAgents: Observable<UserModel[]>;
  // filteredUsers: Observable<UserModel[]>;
  isLocked = false;
  defaultVersion = '0.1';
  project: ProjectModel;
  globalLoading$: Observable<boolean>;
  // testFormula = '@test1234@@country@@siteName@@id@';
  testFormula = '';
  sponsor: { id: number; name: string; image: string };
  sponsors$: Signal<{ id: number; name: string; image: string }[]> = signal<
    {
      id: number;
      name: string;
      image: string;
    }[]
  >([
    { id: 1, name: 'BGO Software', image: 'assets/images/bgo.png' },
    { id: 2, name: 'Sponsor 2', image: 'assets/images/table_icon.png' },
    { id: 3, name: 'Sponsor 3', image: 'assets/images/text_input_icon.png' },
  ]);
  @ViewChild('imageUpload') imageUpload: HTMLInputElement;
  newSponsorMode = false;
  numbersToStart: { value: string; label: string; tooltip: string }[] = [
    {
      value: '1',
      label: '1',
      tooltip: 'Max of 9',
    },
    {
      value: '01',
      label: '01',
      tooltip: 'Max of 99',
    },
    {
      value: '001',
      label: '001',
      tooltip: 'Max of 999',
    },
    {
      value: '0001',
      label: '0001',
      tooltip: 'Max of 9999',
    },
  ];
  dateFormats: { label: string; value: string }[] = [
    { label: 'DD-MMM-YYYY (30-May-2024)', value: 'DD-MMM-YYYY' },
    { label: 'MM-DD-YYYY (30-05-2024)', value: 'MM-DD-YYYY' },
    { label: 'YYYY-MM-DD (2024-05-30)', value: 'YYYY-MM-DD' },
    { label: 'DD.MM.YYYY (30.05.2024)', value: 'DD.MM.YYYY' },
    { label: 'MM/DD/YYYY (05/30/2024)', value: 'MM/DD/YYYY' },
  ];
  testEntity = {
    id: 'Example Study ID',
    siteId: 'EXAMPLE SITE ID',
    country: 'EXAMPLE COUNTRY',
    customField: 'CUSTOM_FIELD',
  };

  filteredSponsors$: Observable<{ id: number; name: string; image: string }[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private projectService: ProjectService,
    private utilsService: UtilsService,
  ) {
    super();
  }

  // public get dossierGroup(): UntypedFormGroup {
  //   return this.form.get('dossierGroup') as UntypedFormGroup;
  // }
  //
  // public get dossier(): UntypedFormControl {
  //   return this.dossierGroup.get('dossier') as UntypedFormControl;
  // }

  // public get dossierAgent(): UntypedFormControl {
  //   return this.dossierGroup.get('agent') as UntypedFormControl;
  // }
  //
  // public get identifier(): UntypedFormControl {
  //   return this.dossierGroup.get('identifier') as UntypedFormControl;
  // }
  //
  // public get formsFormCtrl(): UntypedFormControl {
  //   return this.form.get('forms') as UntypedFormControl;
  // }
  //
  // public get projectTypeFormCtrl(): UntypedFormControl {
  //   return this.form.get('projectType') as UntypedFormControl;
  // }

  ngOnInit(): void {
    this.createInitialForm();
    this.store.dispatch(resetCurrentDataset());
    this.projectState$ = this.store.select(state => state.project.current);
    this.currentProject$ = this.projectState$.pipe(map(current => current.project));
    this.hasPendingChanges$ = this.projectState$.pipe(map(state => state.pendingChanges));
    this.store.dispatch(loadDatasetList());
    this.store.dispatch(getUsersByOrganization());
    this.datasets = this.store.select(state => state.dataset.list);
    this.store.dispatch(updateFormList({ formList: [] }));
    this.forms = this.store.select(state => state.form.list);
    this.globalLoading$ = this.store.select(state => state.ui.globalLoading);
    this.originalProject$ = this.currentProject$.pipe(
      map(project => project.projectDuplicatedId),
      distinctUntilChanged(),
      switchMap(formDuplicateId => {
        if (!formDuplicateId) {
          return of(null);
        }

        return this.projectService.searchById(formDuplicateId);
      }),
    );
    this.amendProject$ = this.currentProject$.pipe(
      map(project => project.projectAmendId),
      distinctUntilChanged(),
      switchMap(projectAmendId => {
        if (!projectAmendId) {
          return of(null);
        }

        return this.projectService.searchById(projectAmendId);
      }),
    );

    this.store
      .select(checkCurrentProjectEditLocked)
      .pipe(takeUntil(this.destroy$))
      .subscribe((/*isLocked: boolean*/) => {
        // this.isLocked = isLocked;
        // if (isLocked) {
        //   this.form.disable({ emitEvent: false });
        //   return;
        // }
        // this.form.enable({ emitEvent: false });
        this.form.disable();
      });

    if (Object.prototype.hasOwnProperty.call(this.route.snapshot.data as { isNewProject?: boolean }, 'isNewProject')) {
      this.isNewProject = this.route.snapshot.data.isNewProject;
    }

    this.synchronizeWithStore();
    this.initComponentSubscriptions();

    this.store.dispatch(successCreateProject({ success: false }));

    this.filteredSponsors$ = this.sponsorFormCtrl.valueChanges.pipe(
      startWith(''),
      filter(value => {
        if (isObject(value)) {
          this.form.controls.sponsor.setValue((value as unknown as { id: number }).id);
          this.selectSponsor(value as unknown as { id: number; name: string; image: string });

          return false;
        }
        return true;
      }),
      map(value => this._filterSponsors(value || '')),
    );
  }

  ngOnDestroy(): void {
    if (getState(this.store).project.current.pendingChanges && !this.isNewProject) {
      this.store.dispatch(refreshCurrentProject());
    }
    super.ngOnDestroy();
  }

  displayFn(e: { name: string }): string {
    return e?.name || '';
  }

  // additionalUserValidation(): void {
  //   this.rebuildDatasetSelectors();
  //   this.store.dispatch(updateProjectFields({
  //     partialProject: {
  //       userID: null,
  //       userFirstNameField: '',
  //       userLastNameField: '',
  //       customerIdentifier: null
  //     }
  //   }));
  // }

  // rebuildRefresh(): void {
  //   this.refresh.next(false);
  //   setTimeout(() => {
  //     this.refresh.next(true);
  //   });
  // }

  addNewSponsor(auto: MatAutocomplete): void {
    auto.showPanel = false;
    this.sponsorFormCtrl.setValue('');
    this.newSponsorMode = true;
    this.sponsor = null;
    this.form.get('newSponsorName').addValidators(Validators.required);
    this.form.get('sponsor').clearValidators();
    this.form.get('sponsor').setValue(null);
    this.form.updateValueAndValidity();
  }

  // rebuildDatasetSelectors(): void {
  //   this.rebuildDatasetFieldSelector.next(false);
  //
  //   setTimeout(() => {
  //     this.rebuildDatasetFieldSelector.next(true);
  //   });
  //   this.rebuildRefresh();
  // }

  returnToDraftAndUnlockProject(): void {
    this.projectState$
      .pipe(
        take(1),
        switchMap(() => {
          return this.confirmationService.openConfirmDialog(
            `You are about to return this Study to <b>Draft</b>, Are you sure?`,
            `Please Confirm`,
            'Yes',
            'No',
          );
          //   const proposedNewVersion = this.utilsService.incrementVersion(
          //     state.projectSnapshot.projectVersion ?? this.defaultVersion,
          //     'minor',
          //   );
          //   const title = `${state.project.name} V${state.projectSnapshot.projectVersion ?? this.defaultVersion}`;
          //
          //   return this.confirmationService
          //     .openConfirmDialog(`Do you want to update the version number to ${proposedNewVersion}?`, title, 'Yes', 'No')
          //     .pipe(
          //       switchMap(isConfirmed => {
          //         if (isConfirmed) {
          //           return of(proposedNewVersion);
          //         }
          //
          //         return this.dialog
          //           .open(VersionInputDialogComponent, {
          //             width: '300px',
          //             data: {
          //               title,
          //               defaultVersion: state.projectSnapshot.projectVersion ?? this.defaultVersion,
          //             },
          //           })
          //           .afterClosed();
          //       }),
          //     );
        }),
        take(1),
        filter(x => !!x),
      )
      .subscribe(() => {
        // let project = {
        //   ...this.projectSignal(),
        //   projectStatus: Statuses.Draft,
        // };
        // if (version) {
        //   project = { ...project, projectVersion: version };
        // }
        this.utilsService.dispatchActions(this.store, [
          pendingCreateProject({ pending: true }),
          dispatchedCreateProject({ dispatched: true }),
          updateProjectStatus({ projectId: this.projectSignal().id, newStatus: Statuses.Draft }),
        ]);
      });
  }

  // setFilteredForms(datasetId: number): void {
  //   this.store.dispatch(loadFormListByDatasetId({ id: datasetId }));
  // }

  // selectAllForms(): void {
  //   const allForms = getState(this.store).form.list.map(a => a.id);
  //   this.formsFormCtrl.patchValue(allForms);
  // }

  selectSponsor(value: { id: number; name: string; image: string }): void {
    if (!value) {
      return;
    }

    this.sponsor = value;
    if (this.newSponsorMode) {
      this.newSponsorMode = false;
      this.form.get('sponsor').addValidators(Validators.required);
      this.form.get('newSponsorName').clearValidators();
      this.form.get('newSponsorName').setValue(null);
      this.form.updateValueAndValidity();
    }
  }

  saveAndCheckPendingChanges(): Observable<boolean> {
    //this method is used in PendingChangesGuard to save pending changes
    //and to check if component can be deactivated
    // if (this.projectTypeFormCtrl.value === ProjectTypeEnum.GeneralPurpose) {
    //   this.store.dispatch(updateProjectFields({
    //     partialProject: {
    //       userID: null,
    //       userLastNameField: '',
    //       userFirstNameField: '',
    //       customerIdentifier: null
    //     }
    //   }));
    //   this.dossier.setErrors(null);
    //   this.identifier.setErrors(null);
    //   this.dossierAgent.setErrors(null);
    // }
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
        if (control instanceof UntypedFormGroup) {
          Object.keys(control.controls).forEach(key => control.get(key).markAsTouched({ onlySelf: true }));
        }
      });
      return of(true);
    }

    this.store.dispatch(globalLoading(true));
    if (!this.projectId) {
      this.utilsService.dispatchActions(this.store, [
        pendingCreateProject({ pending: true }),
        dispatchedCreateProject({ dispatched: true }),
        createProject({ project: getState(this.store).project.current.project }),
      ]);
    } else {
      this.utilsService.dispatchActions(this.store, [
        pendingCreateProject({ pending: true }),
        dispatchedCreateProject({ dispatched: true }),
        updateProject({ project: getState(this.store).project.current.project }),
      ]);
    }

    return this.projectState$.pipe(
      filter(({ dispatched, success }) => dispatched && success),
      tap(() => {
        this.store.dispatch(
          updateContextTitle({
            title: getState(this.store).project.current.project.name,
            position: this.isNewProject ? 2 : 1,
          }),
        );

        this.utilsService.dispatchActions(this.store, [
          dispatchedCreateProject({ dispatched: false }),
          successCreateProject({ success: false }),
        ]);
      }),
      switchMap(() => this.hasPendingChanges$),
      take(1),
    );
  }

  addEditProject(): void {
    this.saveAndCheckPendingChanges()
      .pipe(
        takeUntil(this.destroy$),
        filter(hasPendingChanges => !hasPendingChanges && this.isNewProject),

        /*switchMap(() => this.confirmationService.openConfirmDialog(
          'Navigate to the Assessments or Events tabs to begin building your Schedule of Assessments',
          '',
          'Confirm',
          '',
          false,
          300,
          true,
        )),*/
      )
      .subscribe(() => {
        // this code redirect after new project is created!
        this.router.navigate(['../../edit', getState(this.store).project.current.project.id], {
          relativeTo: this.route,
        });
      });
  }

  originalProjectClick(originalProject: ProjectModel): void {
    this.router.navigate(['../../edit', originalProject.id], { relativeTo: this.route.parent });
  }

  /**
   * sync with store
   */
  private synchronizeWithStore(): void {
    this.currentProject$
      .pipe(
        distinctUntilChanged((oldVal, newVal) => isEqual(oldVal, newVal)),
        // switchMap(() => this.currentProject.pipe(take(1))),
        takeUntil(this.destroy$),
      )
      .subscribe((project: ProjectModel) => {
        const updatedProject: any = project;
        updatedProject.forms = project.forms[0] && project.forms[0].id ? project.forms.map(a => a.id) : project.forms;
        this.form.patchValue(updatedProject, { emitEvent: false });
        this.form.get('projectVersion').setValidators([Validators.required, Validators.pattern(VERSION_PATTERN)]);

        // if (project.userID) {
        //   this.prefillUserById(project.userID);
        // }
        // if (project.agentId) {
        //   this.prefillAgentById(project.agentId);
        // }

        this.projectId = project.id;
        // this.setFilteredForms(project.datasetId);
        // this.store.dispatch(searchDatasetById({ id: project.datasetId }));
        // this.permissionService.hasPermission('ProjectEdit').then(value => {
        //   if (!value) {
        //     this.form.disable();
        //   }
        // });
        //
        // this.projectTypeFormCtrl.disable();
        // this.form.get('isTemplate').disable();
        // if (project.projectType === ProjectTypeEnum.Dossier) {
        //   this.dossierAgent.disable({ emitEvent: false });
        // }
      });

    // this.dossier.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((user) => {
    //   if (!user) {
    //     this.additionalUserValidation();
    //   } else {
    //     this.store.dispatch(updateProjectFields({
    //       partialProject: {
    //         userID: user.userId,
    //         userLastNameField: user.lastName,
    //         userFirstNameField: user.firstName,
    //         customerIdentifier: user.customerIdentifier
    //       }
    //     }));
    //   }
    // })

    // this.form.get('isTemplate').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
    //   this.store.dispatch(updateProjectField({ field: 'isTemplate', value }));
    //   if (value) {
    //     this.resetDossierFormFields();
    //     this.dossierGroup.disable();
    //   } else {
    //     this.dossierGroup.enable();
    //   }
    // })

    // this.dossierGroup.get('agent').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((user) => {
    //   this.store.dispatch(updateProjectField({ field: 'agentId', value: user ? user.userId : null }));
    // })

    // this.projectTypeFormCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(projectType => {
    //   if (projectType === ProjectTypeEnum.GeneralPurpose) {
    //     this.resetDossierFormFields();
    //   }
    //   this.store.dispatch(updateProjectField({ field: 'projectType', value: projectType }));
    // });
    this.form
      .get('name')
      .valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe(title => {
        this.store.dispatch(updateProjectField({ field: 'name', value: title }));
        if (this.isNewProject) {
          this.store.dispatch(updateContextTitle({ title, position: 2 }));
        }
      });

    this.form
      .get('shortName')
      .valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe(shortName => {
        this.store.dispatch(updateProjectField({ field: 'shortName', value: shortName }));
      });

    this.form
      .get('description')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(description => {
        this.store.dispatch(updateProjectField({ field: 'description', value: description }));
      });

    this.form
      .get('projectVersion')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(version => {
        this.store.dispatch(updateProjectField({ field: 'projectVersion', value: version }));
      });

    // this.form.get('datasetId').valueChanges.pipe(takeUntil(this.destroy$))
    //   .subscribe(datasetId => {
    //     if (!this.dossier.value || !this.dossier.value.userId) {
    //       this.store.dispatch(updateProjectFields({
    //         partialProject: {
    //           userLastNameField: '',
    //           userFirstNameField: '',
    //           customerIdentifier: null
    //         }
    //       }));
    //       this.rebuildDatasetSelectors();
    //
    //     }
    //     this.setFilteredForms(datasetId);
    //     this.store.dispatch(searchDatasetById({ id: datasetId }));
    //     this.form.get('recordTitleField').patchValue('');
    //     this.store.dispatch(updateProjectField({ field: 'datasetId', value: datasetId }));
    //     this.formsFormCtrl.patchValue([]);
    //   });

    // this.formsFormCtrl.valueChanges.pipe(takeUntil(this.destroy$))
    //   .subscribe(forms => {
    //     this.store.dispatch(updateProjectField({ field: 'forms', value: forms }));
    //   });

    // this.form.get('recordTitleField').valueChanges.pipe(takeUntil(this.destroy$))
    //   .subscribe((recordTitleField) => {
    //     this.store.dispatch(updateProjectField({ field: 'recordTitleField', value: recordTitleField }));
    //   });

    // this.filteredUsers = this.dossier.valueChanges.pipe(
    //   distinctUntilChanged(),
    //   startWith(''),
    //   filter((value) => !isObject(value)),
    //   map((value) => this._filter(value, 'users')),
    //   takeUntil(this.destroy$)
    // );

    // this.filteredAgents = this.dossierAgent.valueChanges.pipe(
    //   distinctUntilChanged(),
    //   startWith(''),
    //   filter((value) => !isObject(value)),
    //   map((value) => this._filter(value, 'agents')),
    //   takeUntil(this.destroy$)
    // );

    // this.store.select(selectCurrentOrganization).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((org) => {
    //   const customersGroup = org.securityGroupDetails.find(organization => organization.groupType === SecurityGroupTypeEnum.Customer);
    //   const agentsGroup = org.securityGroupDetails.find(organization => organization.groupType === SecurityGroupTypeEnum.Agent);
    //   if (customersGroup) {
    //     this.customersGroupName = customersGroup.value;
    //   }
    //
    //   if (agentsGroup) {
    //     this.agentsGroupName = agentsGroup.value;
    //   }
    // });
  }

  /**
   * create the form
   */
  private createInitialForm(): void {
    this.form = this.fb.group({
      shortName: ['', [Validators.required, Validators.maxLength(500)]],
      name: ['', Validators.required],
      description: ['', Validators.maxLength(500)],
      forms: [],
      datasetId: 0,
      recordTitleField: 0,
      projectType: [ProjectTypeEnum.Dossier, Validators.required],
      isTemplate: [false],
      projectVersion: [this.defaultVersion, [Validators.required, Validators.pattern(VERSION_PATTERN)]],
      sponsorName: [null],
      displayName: [null],
      protocolTitle: [null, Validators.required],
      newSponsorName: [null],
      numberingStart: ['1'],
      dateFormat: [null],
      // dossierGroup: this.fb.group({
      //   dossier: '',
      //   identifier: '',
      //   agent: '',
      // }),
    });
    // this.identifier.disable({ emitEvent: false });
  }

  private initComponentSubscriptions(): void {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data: { isNewProject: boolean }) => {
      if (data.isNewProject) {
        this.isNewProject = true;
      } else {
        this.form.get('datasetId').disable({ emitEvent: false });
      }
    });
    // this.store.select(state => state.user.list).pipe(map((list: UserModel[]) => {
    //   this.fillUserArrays(list);
    // }), takeUntil(this.destroy$)).subscribe()
  }

  private _filterSponsors(value: string) {
    const filterValue = value.toLowerCase();
    return this.sponsors$().filter(option => option.name.toLowerCase().includes(filterValue));
  }

  // /**
  //  * SEt dossier form fields initial state
  //  */
  // private resetDossierFormFields(): void {
  //   this.dossierGroup.enable({ emitEvent: false });
  //   this.rebuildDatasetFieldSelector.next(true);
  //   this.rebuildDatasetSelectors();
  //   this.dossier.patchValue('');
  //   this.dossierGroup.get('agent').patchValue('');
  //   this.identifier.disable();
  //   this.identifier.patchValue('');
  //   this.store.dispatch(updateProjectFields({
  //     partialProject: {
  //       userID: null,
  //       userFirstNameField: '',
  //       userLastNameField: '',
  //       customerIdentifier: null,
  //       agentId: null
  //     }
  //   }));
  // }

  // private _filter(value: any, type: 'users' | 'agents'): any[] {
  //   if (!value) {
  //     return type === 'users' ? this.dossierUsers.getValue() : this.agentUsers.getValue();
  //   }
  //   const filterValue = value.toLowerCase();
  //   if (type === 'agents') {
  //     return this.agentUsers.getValue().filter((option) => {
  //       return option.firstName.toLowerCase().includes(filterValue) ||
  //         option.lastName.toLowerCase().includes(filterValue);
  //     });
  //   } else {
  //     return this.dossierUsers.getValue().filter((option) => {
  //       return option.firstName.toLowerCase().includes(filterValue) ||
  //         option.lastName.toLowerCase().includes(filterValue);
  //     });
  //   }
  //
  // }

  // private fillUserArrays(list: UserModel[]): void {
  //   const patients = [];
  //   const doctors = [];
  //   list.forEach((user: UserModel) => {
  //     // customers
  //     if (_find(user.securityGroup, ['groupType', SecurityGroupTypeEnum.Customer])) {
  //       patients.push(user);
  //     }
  //     // agents
  //     if (_find(user.securityGroup, ['groupType', SecurityGroupTypeEnum.Agent])) {
  //       doctors.push(user);
  //     }
  //   });
  //   this.agentUsers.next(doctors);
  //   this.dossierUsers.next(patients);
  // }

  // prefillUserById(id): void {
  //   this.store.select(state => state.user.list).pipe(take(1))
  //     .subscribe((users) => {
  //       const user = users.find(u => u.userId === id);
  //       if (user) {
  //         this.rebuildDatasetFieldSelector.next(false);
  //         this.dossier.patchValue(user, { emitEvent: false, onlySelf: false });
  //         this.identifier.disable();
  //         this.identifier.patchValue(user.customerIdentifier, { emitEvent: false, onlySelf: false });
  //
  //       }
  //     });
  // }
  //
  // prefillAgentById(id): void {
  //   this.store.select(state => state.user.list).pipe(take(1))
  //     .subscribe((users: UserModel[]) => {
  //       const user = users.find((u: UserModel) => u.userId === id);
  //       if (user) {
  //         this.dossierGroup.get('agent').patchValue(user, { emitEvent: false, onlySelf: false });
  //       }
  //     });
  // }
  protected readonly Statuses = Statuses;
  protected readonly PermissionsEnum = PermissionsEnum;
}
