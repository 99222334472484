import { mapToCanDeactivate, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NewProjectComponent } from './new-project/new-project.component';
import { ListProjectsComponent } from './list-projects/list-projects.component';
import { ProjectContextComponent } from './project-context/project-context.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { FormTypeEnum } from '../form/form.model';
import { ExportComponent } from '../shared/export/export.component';
import { PendingChangesGuard } from '../shared/guards/pending-changes.guard';
import { AssignListComponent } from '../assign/assign-list/assign-list.component';
import { EventsComponent } from '../events/events/events.component';
import { StudyApprovalsComponent } from './study-approvals/study-approvals.component';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { PermissionsEnum } from '../permission/permissions.enum';

/**
 * form routes
 */
const routes: Routes = [
  {
    path: '',
    // canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: '',
        //only: 'ProjectRead',
        redirectTo: ['dashboard/workspace'],
      },
    },
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      {
        path: 'edit/:projectId',
        component: ProjectContextComponent,
        data: { noReuseParamsChange: true },
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'prefix' },
          {
            path: 'details',
            component: ProjectDetailsComponent,
            // canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
            canActivate: [ngxPermissionsGuard],
            data: {
              permissions: {
                only: [PermissionsEnum.ReadStudy],
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'form',
            loadChildren: () => import('../form/form.module').then(m => m.PharFormModule),
            data: {
              types: [FormTypeEnum.All, FormTypeEnum.DataCapture, FormTypeEnum.Questionnaire, FormTypeEnum.Report],
              isContext: true,
            },
          },
          {
            path: 'assessments',
            component: AssignListComponent,
            canActivate: [ngxPermissionsGuard],
            data: {
              permissions: {
                only: PermissionsEnum.ListAssessments,
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'events',
            component: EventsComponent,
            canActivate: [ngxPermissionsGuard],
            data: {
              permissions: {
                only: PermissionsEnum.ListEvents,
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'admins',
            loadChildren: () => import('./study-admin/study-admin.module').then(m => m.StudyAdminModule),
            data: {
              permissions: {
                only: '',
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'sites-study-team',
            loadChildren: () =>
              import('./sites-and-study-team/sites-and-study-team.module').then(m => m.SitesAndStudyTeamModule),
            // canActivate: [ngxPermissionsGuard],
            data: {
              // TODO
              permissions: {
                only: '',
                //only: 'AssignmentEdit',
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'schedule-assessments',
            loadChildren: () => import('../study-matrix/study-matrix.module').then(m => m.StudyMatrixModule),
            canActivate: [ngxPermissionsGuard],
            data: {
              permissions: {
                only: PermissionsEnum.ListSofa,
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'approvals',
            component: StudyApprovalsComponent,
            // canActivate: [ngxPermissionsGuard],
            data: {
              // TODO
              permissions: {
                only: '',
                //only: 'AssignmentEdit',
                redirectTo: ['dashboard/study'],
              },
            },
          },
          {
            path: 'export-study',
            component: ExportComponent,
          },
          // {
          //   path: 'data', component: ProjectDataComponent,
          //   children: [
          //     { path: '', redirectTo: 'forms', pathMatch: 'prefix' },
          //     {
          //       path: 'views',
          //       component: ProjectDataViewsComponent,
          //       canActivate: [ngxPermissionsGuard],
          //       data: {
          //         permissions: {
          //           only: 'RecordRead',
          //           redirectTo: ['dashboard/workspace']
          //         }
          //       }
          //     },
          //   ]
          // },
          // {
          //   path: 'group',
          //   loadChildren: () => import('../group/group.module').then(m => m.GroupModule),
          //   canActivate: [ngxPermissionsGuard],
          //   data: {
          //     permissions: {
          //       only: 'ViewSecurityGroups',
          //       redirectTo: ['dashboard/workspace']
          //     }
          //   }
          // },
        ],
      },
      {
        path: 'new',
        component: NewProjectComponent,
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'prefix' },
          {
            path: 'details',
            component: ProjectDetailsComponent,
            canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
            data: { isNewProject: true },
          },
        ],
      },
      { path: 'list', component: ListProjectsComponent },
    ],
  },
];

/**
 * This module is responsible only for form routes
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

/**
 * App routing class
 */
export class ProjectRoutingModule {}
