import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { AssignModel } from '../assign.model';
import { Statuses } from '../../shared/models/statuses.enum';

export const ASSIGN_LIST_COLUMN_CONFIG: DraggableListViewConfigModel = {
  columns: [
    {
      field: 'drag',
      size: '30px',
      title: '',
      show: true,
      hasTemplate: true,
      template: null,
    },
    {
      field: 'id',
      size: '0',
      title: 'ID',
      show: false,
      hasTemplate: false,
      template: null,
    },
    {
      field: 'expand',
      size: '40px',
      title: '',
      show: true,
      hasTemplate: true,
      template: null,
    },
    {
      field: 'formName',
      title: 'Assessment Name',
      size: '1.1fr',
      show: true,
      order: true,
      hasTemplate: true,
      template: null,
      selectable: true,
    },
    {
      field: 'form',
      title: 'Version',
      size: '0.4fr',
      show: true,
      order: true,
      hasTemplate: true,
      template: null,
      selectable: true,
    },
    {
      field: 'assignmentType',
      title: 'Assessment Type',
      size: '0.4fr',
      show: true,
      order: true,
      hasTemplate: true,
      template: null,
      selectable: true,
    },
    {
      field: 'status', // @TODO change this when we have status
      title: 'Status',
      size: '0.4fr',
      show: true,
      order: true,
      hasTemplate: true,
      template: null,
      selectable: true,
    },
    {
      field: 'changeStatus',
      title: 'Change status',
      size: '0.4fr',
      show: true,
      order: true,
      hasTemplate: true,
      template: null,
      selectable: true,
      hasHeaderCellTemplate: true,
      headerTemplate: null,
    },
    {
      field: 'userIdUpdated', // placeholder for comments
      title: 'Comments',
      size: '0.4fr',
      show: true,
      order: false,
      hasTemplate: true,
      template: null,
      selectable: true,
    },
    {
      field: 'updatedAt', // some field for placeholder
      title: 'Actions',
      size: '50px',
      // Add as many pixels as left margin added for nested columns (2rem).
      sizeNested: '86px',
      show: true,
      hasTemplate: true,
      template: null,
    },
  ],
  actions: [],
};

export const EMPTY_ASSIGNMENT: AssignModel = {
  id: null,
  groupName: null,
  projectId: null,
  orderPriority: null,
  assignmentType: null,
  formId: null,
  parentId: null,
  userIdUpdated: null,
  userIds: [],
  userIdCreated: null,
  organizationId: null,
  securityGroupId: null,
  emails: [],
  updatedAt: '',
  createdAt: '',
  formName: '',
  permissionsIds: [],
  accessUrl: '',
  securityGroupType: null,
  status: Statuses.PendingReview,
};

export enum AssignItemActions {
  Edit = 'edit',
  Duplicate = 'duplicate',
  Delete = 'delete',
  Preview = 'preview',
  Comments = 'comments',
  Replace = 'replace',
}
