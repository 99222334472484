<div id="steps-container" class="d-flex flex-column">
  @for (step of steps(); let i = $index; track step.status) {
    <phar-approval-step [index]="i + 1" [step]="step">
      <ng-template [ngTemplateOutletContext]="{ $implicit: step }" [ngTemplateOutlet]="actionsTemplate" />
    </phar-approval-step>
  }
  @if (statusHistory().history.length > 0) {
    @for (step of statusHistory().history; track index; let index = $index) {
      <phar-approval-step [step]="step">
        <ng-template
          [ngTemplateOutletContext]="{ $implicit: step, history: true }"
          [ngTemplateOutlet]="actionsTemplate" />
      </phar-approval-step>
    }
  }
</div>

<ng-template #actionsTemplate let-step let-history="history">
  <div class="step-content w-100 d-flex align-items-center">
    @switch (step.status) {
      @case (Statuses.Draft) {
        <ng-template [ngTemplateOutletContext]="{ $implicit: step, history: false }" [ngTemplateOutlet]="stepBodyTmp" />
        @if (step.active && !step.done) {
          <div class="step-actions ms-auto">
            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.PendingReview,
                label: 'Send for review',
                icon: 'send',
                disabled: disabled(),
                permission: PermissionsEnum.ChangeStatusSofaSendForReview,
              }"
              [ngTemplateOutlet]="buttonTemplate" />
          </div>
        }
      }
      @case (Statuses.ReadyForReview) {
        @if (step.done || step.active) {
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: step, history: false }"
            [ngTemplateOutlet]="stepBodyTmp" />
        }
        @if (step.active && !step.done) {
          <div class="step-actions ms-auto">
            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.UnderReview,
                label: 'Start review',
                icon: 'visibility',
                disabled: disabled(),
                permission: PermissionsEnum.ChangeStatusSofaStartReview,
              }"
              [ngTemplateOutlet]="buttonTemplate" />
          </div>
        }
      }
      @case (Statuses.UnderReview) {
        @if (step.done || step.active) {
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: step, history: false }"
            [ngTemplateOutlet]="stepBodyTmp" />
        }
        @if (step.active && !step.done) {
          <div class="step-actions ms-auto">
            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.Rejected,
                label: 'Reject',
                icon: 'close',
                type: 'warn',
                additionalClass: 'me-2',
                disabled: disabled(),
                permission: PermissionsEnum.ChangeStatusSofaReject,
              }"
              [ngTemplateOutlet]="buttonTemplate" />

            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.Released,
                label: 'Release',
                icon: 'check',
                disabled: disabled(),
                permission: PermissionsEnum.ChangeStatusSofaRelease,
              }"
              [ngTemplateOutlet]="buttonTemplate" />
          </div>
        }
      }
      @case (Statuses.Rejected) {
        @if (step.done) {
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: step, history: false }"
            [ngTemplateOutlet]="stepBodyTmp" />
        }
      }
      @case (Statuses.Released) {
        @if (history) {
          <div class="step-body me-auto d-flex align-items-center">
            <ng-template
              [ngTemplateOutletContext]="{ $implicit: step, history: true }"
              [ngTemplateOutlet]="stepBodyTmp" />

            @if (step.environment) {
              <span class="published-to ms-3">Published</span>
              <div class="ms-3 text-uppercase fw-bold">
                {{ step.environment }}
              </div>
            }
          </div>
          @if (step.active) {
            <div class="step-actions">
              <phar-button
                *ngxPermissionsOnly="PermissionsEnum.ChangeStatusSofaStartRevision"
                [state]="'warning'"
                [disabled]="!step.environment || disabled()"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionInProgress, step })">
                Start Revision
              </phar-button>
              <phar-button
                *ngxPermissionsOnly="PermissionsEnum.ChangeStatusSofaPublish"
                [disabled]="!!step.environment || disabled()"
                [state]="'success'"
                [class]="'ms-2'"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.Published, step })">
                Publish
              </phar-button>
            </div>
          }
        }
      }
      @case (Statuses.RevisionInProgress) {
        <ng-template [ngTemplateOutletContext]="{ $implicit: step, history: true }" [ngTemplateOutlet]="stepBodyTmp" />

        @if (step.active) {
          <div class="step-actions ms-auto">
            @if (step.active) {
              <div class="step-actions ms-auto">
                @if (step.done) {
                  <phar-button
                    *ngxPermissionsOnly="PermissionsEnum.ChangeStatusSofaRejectRevision"
                    [state]="'warn'"
                    [class]="'ms-2'"
                    [disabled]="disabled()"
                    (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionRejected, step })">
                    REJECT REVISION
                  </phar-button>

                  <phar-button
                    *ngxPermissionsOnly="PermissionsEnum.ChangeStatusSofaRelease"
                    [state]="'success'"
                    [class]="'ms-2'"
                    [disabled]="disabled()"
                    (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.Released, step })">
                    RELEASE
                  </phar-button>
                } @else {
                  <phar-button
                    *ngxPermissionsOnly="PermissionsEnum.ChangeStatusSofaCompleteRevision"
                    [state]="'success'"
                    [disabled]="step.done || disabled()"
                    (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionCompleted, step })">
                    REVISION COMPLETED
                  </phar-button>
                }
              </div>
            }
          </div>
        }
      }
      @case (Statuses.RevisionRejected) {
        <ng-template [ngTemplateOutletContext]="{ $implicit: step, history: true }" [ngTemplateOutlet]="stepBodyTmp" />

        @if (step.active) {
          <div class="step-actions ms-auto">
            <phar-button
              *ngxPermissionsOnly="PermissionsEnum.ChangeStatusSofaReturnToRevision"
              [state]="'success'"
              [disabled]="disabled()"
              (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionInProgress, step })">
              RETURN TO REVISION
            </phar-button>
          </div>
        }
      }
    }
  </div>
</ng-template>

<ng-template #stepBodyTmp let-step let-history="history">
  <div class="step-body">
    <ng-template [ngTemplateOutletContext]="{ $implicit: step, history: history }" [ngTemplateOutlet]="dateTemplate" />
    <span class="fw-bold ms-1"> V{{ step.version || 1 }}</span>
  </div>
</ng-template>

<ng-template #dateTemplate let-step let-history="history">
  @if (history) {
    {{ step?.createdAt | date: 'd MMM y HH:mm:ss' }}
  } @else if (statusHistory()[step.status] && statusHistory()[step.status].last) {
    {{ statusHistory()[step.status].last.createdAt | date: 'd MMM y HH:mm:ss' }}
  }
</ng-template>

<ng-template
  #buttonTemplate
  let-nextStatus="nextStatus"
  let-label="label"
  let-icon="icon"
  let-type="type"
  let-additionalClass="additionalClass"
  let-disabled="disabled"
  let-permission="permission">
  @let btnState = type || 'success';
  <phar-button
    *ngxPermissionsOnly="'permission'"
    [state]="btnState"
    [class]="additionalClass || ''"
    [disabled]="disabled"
    (btnClicked)="requestStatusChange.emit({ nextStatus })">
    <mat-icon [svgIcon]="icon" />
    {{ label }}
  </phar-button>
</ng-template>
